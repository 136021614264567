"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");
require("./../../bower_components/parsleyjs/dist/parsley.js");
require("./../../bower_components/parsleyjs/dist/i18n/ru.js");

var initCustomWidgets = function () {
	require('./modules/promoInit')();
	require('./modules/telsInit')();
	require('./modules/reviewsInit')();
	require('./modules/clientsInit')();
	require('./modules/callorderInit')();
	require('./modules/requestInit')();
};

$(initCustomWidgets);
