"use strict";
require("./../../../bower_components/magnific-popup/dist/jquery.magnific-popup.js");

module.exports = function () {
	
	$('a[href="#request"]').magnificPopup({
		type: 'inline',
		preloader: false,
		showCloseBtn: false,
		removalDelay: 150
	});
	
	$(document).on('click', '.popup__close', function(e) {
		$.magnificPopup.close();
		e.preventDefault();
	});

	$('#request-form').on('submit', function(e){
		var $form = $(this);

		$form.parsley().validate();

		if ($form.parsley().isValid()) {

			$.magnificPopup.open({
				items: {
					src: '#success',
					type: 'inline'
				},
				preloader: false,
				showCloseBtn: false,
				removalDelay: 150
			});

		}

		e.preventDefault();
	});

};