"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.clients__slider')
		.after('<div class="arrow arrow_type_prev clients__prev"/><div class="arrow arrow_type_next clients__next"/>')
		.swiper({
			loop: true,
			slidesPerView: 4,
			spaceBetween: 40,
			prevButton: '.clients__prev',
			nextButton: '.clients__next'			
		});

};