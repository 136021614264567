"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.reviews__slider')
		.after('<div class="reviews__pagination"/>')
		.append('<div class="arrow arrow_type_prev reviews__prev"/><div class="arrow arrow_type_next reviews__next"/>')
		.swiper({
			loop: true,
			speed: 750,
			pagination: '.reviews__pagination',
			paginationClickable: true,
			bulletClass: 'reviews__bullet',
			bulletActiveClass: 'reviews__bullet_active',
			prevButton: '.reviews__prev',			
			nextButton: '.reviews__next'			
		});

};