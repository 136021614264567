"use strict";
require("./../../../bower_components/swiper/dist/js/swiper.js");

module.exports = function () {
	
	$('.promo')
		.append('<div class="promo__pagination"/>')
		.swiper({
			loop: true,
			speed: 750,
			autoplay: 6000,
			pagination: '.promo__pagination',
			paginationClickable: true,
			bulletClass: 'promo__bullet',
			bulletActiveClass: 'promo__bullet_active'
		});

};